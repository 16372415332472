import React, { useEffect } from "react";
import { useState } from "react";
import "./Final.css";

function Final({ onClick, responseState, params }) {
    const [buttonSendStatus, setButtonSendStatus] = useState(false);

    function activateButtonSend() {
        const inputName = document.getElementById("inputName");
        const inputMailAdress = document.getElementById("inputMailAdress");
        const inputTel = document.getElementById("inputTel");
        const checkboxPrivacy = document.getElementById("checkboxPrivacy");

        if (inputName.value.length > 1 && inputMailAdress.value.length > 1 && inputTel.value.length > 1 && checkboxPrivacy.checked) {
            setButtonSendStatus(true);
        } else {
            setButtonSendStatus(false);
        }
    }

    useEffect(() => {
        if (params) {
            document.getElementById("inputName").value = params[1];
            document.getElementById("inputMailAdress").value = params[2];
            document.getElementById("inputTel").value = params[3];
        }
    }, []);

    return (
        <div className="Final">
            <input type="text" id="inputName" placeholder="Vor- &amp; Nachname" onInput={activateButtonSend} />
            <input type="email" id="inputMailAdress" placeholder="E-Mail Adresse" onInput={activateButtonSend} />
            <input type="tel" id="inputTel" placeholder="Telefonnummer" onInput={activateButtonSend} />
            <label htmlFor="" style={{ marginBottom: "2rem" }}>
                <input type="checkbox" name="" id="checkboxPrivacy" onChange={activateButtonSend} />
                Ich habe die
                <a href="https://b-smile.de/datenschutz"> Datenschutzbestimmungen </a>
                gelesen und akzeptiere diese. Des Weiteren bin ich damit einverstanden, dass ich für den weiteren Bewerbungsprozess per Telefon oder E-Mail
                kontaktiert werde.
            </label>
            {responseState === "waiting" && (
                <button id="buttonSend" className={responseState} onClick={onClick} disabled={!buttonSendStatus}>
                    <span className="material-icons">upload_file</span>
                    Bewerbung absenden
                </button>
            )}
            {responseState === "loading" && (
                <button id="buttonSend" className={responseState} onClick={onClick} disabled={buttonSendStatus}>
                    <span className="material-icons">refresh</span>
                    Bitte warten...
                </button>
            )}
            {responseState === "error" && (
                <button id="buttonSend" className={responseState} onClick={onClick} disabled={buttonSendStatus}>
                    <span className="material-icons">close</span>
                    Bitte erneut versuchen
                </button>
            )}
        </div>
    );
}

export default Final;
