import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import "./App.css";
import Instance from "./Instance";
import Blank from "./componets/Blank.jsx";

function App() {
    const dataSetAllgemeinPraktikant = [
        {
            key: 0,
            type: "Mcq",
            question: "Welchen Abschluss strebst Du aktuell an?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "school",
                    text: "Hauptschule",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "school",
                    text: "Realschule",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "school",
                    text: "Abitur",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "close",
                    text: "keiner der Genannten",
                },
            ],
        },
        {
            key: 1,
            type: "Mcq",
            question: "In welcher Klassenstufe bist Du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "class",
                    text: "5 bis 7 Klasse",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "class",
                    text: "8 bis 10 Klasse",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "class",
                    text: "11 bis 12 Klasse",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "close",
                    text: "keiner der Genannten",
                },
            ],
        },
        {
            key: 2,
            type: "Mcq",
            question: "Ab wann soll Dein Praktikum starten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "fast_forward",
                    text: "so schnell wie möglich",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "date_range",
                    text: "nächsten Monat",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "event",
                    text: "noch dieses Jahr",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "calendar_today",
                    text: "nächstes Jahr",
                },
            ],
        },
        {
            key: 3,
            type: "Cbq",
            question: "Wie bist Du auf uns aufmerksam geworden?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Google",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Facebook / Instagram",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Empfehlung",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Zufall",
                },
            ],
        },
    ];

    const dataSetAllgemeinAusbildung = [
        {
            key: 0,
            type: "Mcq",
            question: "Wie alt sind bist du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "person",
                    text: "16 bis 30 Jahre",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "person",
                    text: "über 30 Jahre",
                },
            ],
        },
        {
            key: 1,
            type: "Mcq",
            question: "Welchen Schulabschluss hast Du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "school",
                    text: "Hauptschule",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "school",
                    text: "Realschule",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "school",
                    text: "Abitur",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "close",
                    text: "keiner der Genannten",
                },
            ],
        },
        {
            key: 2,
            type: "Mcq",
            question: "Ab wann möchtest Du bei uns starten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "fast_forward",
                    text: "so schnell wie möglich",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "date_range",
                    text: "nächsten Monat",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "event",
                    text: "noch dieses Jahr",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "calendar_today",
                    text: "nächstes Jahr",
                },
            ],
        },
        {
            key: 3,
            type: "Saq",
            question: "Möchtest Du uns noch etwas mitteilen?",
            anwsers: [],
            optional: true,
        },
        {
            key: 4,
            type: "Doc",
            question: "Hier kannst Du Dokumente hochladen.",
            anwsers: [],
            optional: true,
        },
        {
            key: 5,
            type: "Cbq",
            question: "Wie bist Du auf uns aufmerksam geworden?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Google",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Facebook / Instagram",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Empfehlung",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Zufall",
                },
            ],
        },
    ];

    const dataSetMitarbeiterZahnarzt = [
        {
            key: 0,
            type: "Cbq",
            question: "In welchen Gebieten bist Du spezialisiert?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Prothetik",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Endodontie",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Chirurgie",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Parodontologie",
                },
                {
                    key: 4,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Funktionsanalyse",
                },
                {
                    key: 5,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Kieferorthopädie",
                },
            ],
        },
        {
            key: 1,
            type: "Mcq",
            question: "Wie viel Berufserfahrung hast Du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "calendar_today",
                    text: "1 bis 3 Jahre",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "calendar_today",
                    text: "mehr als 3 Jahre",
                },
            ],
        },
        {
            key: 2,
            type: "Mcq",
            question: "Wie alt sind bist du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "person",
                    text: "18 bis 30 Jahre",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "person",
                    text: "über 30 Jahre",
                },
            ],
        },
        {
            key: 3,
            type: "Mcq",
            question: "Wie viel Stunden möchest Du Arbeiten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "schedule",
                    text: "20 bis 30 Stunden",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "schedule",
                    text: "über 30 Stunden",
                },
            ],
        },
        {
            key: 4,
            type: "Mcq",
            question: "Ab wann möchtest Du bei uns starten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "fast_forward",
                    text: "so schnell wie möglich",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "date_range",
                    text: "nächsten Monat",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "event",
                    text: "noch dieses Jahr",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "calendar_today",
                    text: "nächstes Jahr",
                },
            ],
        },
        {
            key: 5,
            type: "Saq",
            question: "Möchtest Du uns noch etwas mitteilen?",
            anwsers: [],
            optional: true,
        },
        {
            key: 6,
            type: "Doc",
            question: "Hier kannst Du Dokumente hochladen.",
            anwsers: [],
            optional: true,
        },
        {
            key: 7,
            type: "Cbq",
            question: "Wie bist Du auf uns aufmerksam geworden?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Google",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Facebook / Instagram",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Empfehlung",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Zufall",
                },
            ],
        },
    ];

    const dataSetMitarbeiterZMPZMF = [
        {
            key: 0,
            type: "Cbq",
            question: "In welchen Gebieten bist Du spezialisiert?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Abrechnung",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Prophylaxe",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Praxismanagement",
                },
            ],
        },
        {
            key: 1,
            type: "Mcq",
            question: "Wie viel Berufserfahrung hast Du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "calendar_today",
                    text: "1 bis 3 Jahre",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "calendar_today",
                    text: "mehr als 3 Jahre",
                },
            ],
        },
        {
            key: 2,
            type: "Mcq",
            question: "Wie alt sind bist du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "person",
                    text: "18 bis 30 Jahre",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "person",
                    text: "über 30 Jahre",
                },
            ],
        },
        {
            key: 3,
            type: "Mcq",
            question: "Wie viel Stunden möchest Du Arbeiten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "schedule",
                    text: "20 bis 30 Stunden",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "schedule",
                    text: "über 30 Stunden",
                },
            ],
        },
        {
            key: 4,
            type: "Mcq",
            question: "Ab wann möchtest Du bei uns starten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "fast_forward",
                    text: "so schnell wie möglich",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "date_range",
                    text: "nächsten Monat",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "event",
                    text: "noch dieses Jahr",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "calendar_today",
                    text: "nächstes Jahr",
                },
            ],
        },
        {
            key: 5,
            type: "Saq",
            question: "Möchtest Du uns noch etwas mitteilen?",
            anwsers: [],
            optional: true,
        },
        {
            key: 6,
            type: "Doc",
            question: "Hier kannst Du Dokumente hochladen.",
            anwsers: [],
            optional: true,
        },
        {
            key: 7,
            type: "Cbq",
            question: "Wie bist Du auf uns aufmerksam geworden?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Google",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Facebook / Instagram",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Empfehlung",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Zufall",
                },
            ],
        },
    ];

    const dataSetMitarbeiterAssistenz = [
        {
            key: 0,
            type: "Cbq",
            question: "In welchen Gebieten bist Du spezialisiert?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Stuhlassistenz",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Sterie",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Abdrücke",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Herstellung Prov.",
                },
                {
                    key: 4,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Computer",
                },
            ],
        },
        {
            key: 1,
            type: "Mcq",
            question: "Wie viel Berufserfahrung hast Du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "calendar_today",
                    text: "1 bis 3 Jahre",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "calendar_today",
                    text: "mehr als 3 Jahre",
                },
            ],
        },
        {
            key: 2,
            type: "Mcq",
            question: "Wie alt sind bist du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "person",
                    text: "18 bis 30 Jahre",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "person",
                    text: "über 30 Jahre",
                },
            ],
        },
        {
            key: 3,
            type: "Mcq",
            question: "Wie viel Stunden möchest Du Arbeiten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "schedule",
                    text: "20 bis 30 Stunden",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "schedule",
                    text: "über 30 Stunden",
                },
            ],
        },
        {
            key: 4,
            type: "Mcq",
            question: "Ab wann möchtest Du bei uns starten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "fast_forward",
                    text: "so schnell wie möglich",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "date_range",
                    text: "nächsten Monat",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "event",
                    text: "noch dieses Jahr",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "calendar_today",
                    text: "nächstes Jahr",
                },
            ],
        },
        {
            key: 5,
            type: "Saq",
            question: "Möchtest Du uns noch etwas mitteilen?",
            anwsers: [],
            optional: true,
        },
        {
            key: 6,
            type: "Doc",
            question: "Hier kannst Du Dokumente hochladen.",
            anwsers: [],
            optional: true,
        },
        {
            key: 7,
            type: "Cbq",
            question: "Wie bist Du auf uns aufmerksam geworden?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Google",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Facebook / Instagram",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Empfehlung",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Zufall",
                },
            ],
        },
    ];

    const dataSetMitarbeiterZahntechniker = [
        {
            key: 0,
            type: "Cbq",
            question: "In welchen Gebieten bist Du spezialisiert?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "CAD/CAM",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Keramik",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Kunststoff",
                },
            ],
        },
        {
            key: 1,
            type: "Mcq",
            question: "Wie viel Berufserfahrung hast Du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "calendar_today",
                    text: "1 bis 3 Jahre",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "calendar_today",
                    text: "mehr als 3 Jahre",
                },
            ],
        },
        {
            key: 2,
            type: "Mcq",
            question: "Wie alt sind bist du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "person",
                    text: "18 bis 30 Jahre",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "person",
                    text: "über 30 Jahre",
                },
            ],
        },
        {
            key: 3,
            type: "Mcq",
            question: "Wie viel Stunden möchest Du Arbeiten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "schedule",
                    text: "20 bis 30 Stunden",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "schedule",
                    text: "über 30 Stunden",
                },
            ],
        },
        {
            key: 4,
            type: "Mcq",
            question: "Ab wann möchtest Du bei uns starten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "fast_forward",
                    text: "so schnell wie möglich",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "date_range",
                    text: "nächsten Monat",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "event",
                    text: "noch dieses Jahr",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "calendar_today",
                    text: "nächstes Jahr",
                },
            ],
        },
        {
            key: 5,
            type: "Saq",
            question: "Möchtest Du uns noch etwas mitteilen?",
            anwsers: [],
            optional: true,
        },
        {
            key: 6,
            type: "Doc",
            question: "Hier kannst Du Dokumente hochladen.",
            anwsers: [],
            optional: true,
        },
        {
            key: 7,
            type: "Cbq",
            question: "Wie bist Du auf uns aufmerksam geworden?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Google",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Facebook / Instagram",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Empfehlung",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Zufall",
                },
            ],
        },
    ];

    const dataSetMitarbeiterQuereinsteiger = [
        {
            key: 0,
            type: "Saq",
            question: "In welchen Gebieten bist Du spezialisiert?",
            anwsers: [],
            optional: false,
        },
        {
            key: 1,
            type: "Mcq",
            question: "Wie viel Berufserfahrung hast Du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "calendar_today",
                    text: "1 bis 3 Jahre",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "calendar_today",
                    text: "mehr als 3 Jahre",
                },
            ],
        },
        {
            key: 2,
            type: "Mcq",
            question: "Wie alt sind bist du?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "person",
                    text: "18 bis 30 Jahre",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "person",
                    text: "über 30 Jahre",
                },
            ],
        },
        {
            key: 3,
            type: "Mcq",
            question: "Wie viel Stunden möchest Du Arbeiten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "height",
                    icon: "schedule",
                    text: "20 bis 30 Stunden",
                },
                {
                    key: 1,
                    iconAddon: "straight",
                    icon: "schedule",
                    text: "über 30 Stunden",
                },
            ],
        },
        {
            key: 4,
            type: "Mcq",
            question: "Ab wann möchtest Du bei uns starten?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "fast_forward",
                    text: "so schnell wie möglich",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "date_range",
                    text: "nächsten Monat",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "event",
                    text: "noch dieses Jahr",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "calendar_today",
                    text: "nächstes Jahr",
                },
            ],
        },
        {
            key: 5,
            type: "Saq",
            question: "Möchtest Du uns noch etwas mitteilen?",
            anwsers: [],
            optional: true,
        },
        {
            key: 6,
            type: "Doc",
            question: "Hier kannst Du Dokumente hochladen.",
            anwsers: [],
            optional: true,
        },
        {
            key: 7,
            type: "Cbq",
            question: "Wie bist Du auf uns aufmerksam geworden?",
            anwsers: [
                {
                    key: 0,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Google",
                },
                {
                    key: 1,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Facebook / Instagram",
                },
                {
                    key: 2,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Empfehlung",
                },
                {
                    key: 3,
                    iconAddon: "",
                    icon: "favorite_border",
                    text: "Zufall",
                },
            ],
        },
    ];

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Blank />} />
                <Route path="/:url" element={<Blank />} />
                <Route path="/praktikant/" element={<Instance questions={dataSetAllgemeinPraktikant} stelle={"Praktikant"} />} />
                <Route path="/ausbildung/" element={<Instance questions={dataSetAllgemeinAusbildung} stelle={"Ausbildung"} />} />
                <Route path="/zahnarzt/" element={<Instance questions={dataSetMitarbeiterZahnarzt} stelle={"Zahnarzt"} />} />
                <Route path="/zmpzmf/" element={<Instance questions={dataSetMitarbeiterZMPZMF} stelle={"ZMP/ZMF"} />} />
                <Route path="/assistenz/" element={<Instance questions={dataSetMitarbeiterAssistenz} stelle={"Assistenz"} />} />
                <Route path="/zahntechniker/" element={<Instance questions={dataSetMitarbeiterZahntechniker} stelle={"Zahntechniker"} />} />
                <Route path="/quereinsteiger/" element={<Instance questions={dataSetMitarbeiterQuereinsteiger} stelle={"Quereinsteiger"} />} />
                <Route path="/praktikant/:param" element={<Instance questions={dataSetAllgemeinPraktikant} stelle={"Praktikant"} />} />
                <Route path="/ausbildung/:param" element={<Instance questions={dataSetAllgemeinAusbildung} stelle={"Ausbildung"} />} />
                <Route path="/zahnarzt/:param" element={<Instance questions={dataSetMitarbeiterZahnarzt} stelle={"Zahnarzt"} />} />
                <Route path="/zmpzmf/:param" element={<Instance questions={dataSetMitarbeiterZMPZMF} stelle={"ZMP/ZMF"} />} />
                <Route path="/assistenz/:param" element={<Instance questions={dataSetMitarbeiterAssistenz} stelle={"Assistenz"} />} />
                <Route path="/zahntechniker/:param" element={<Instance questions={dataSetMitarbeiterZahntechniker} stelle={"Zahntechniker"} />} />
                <Route path="/quereinsteiger/:param" element={<Instance questions={dataSetMitarbeiterQuereinsteiger} stelle={"Quereinsteiger"} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
