import React from "react";
import { useState } from "react";
import "./Cbq.css";

function Cbq({ id, question, onChange }) {
    return (
        <label className="Cbq">
            <input
                className="Cbq__checkbox"
                id={id}
                type="checkbox"
                onChange={onChange}
            />
            {question}
        </label>
    );
}

export default Cbq;
