import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

function Blank() {
    const [progress, setProgress] = useState(100);
    return (
        <div className="wrapper">
            <Header progress={progress} />
            <div className="wrapper--question">
                <span>Keine Fragen vorhanden. Bitte zur Übersicht zurückkehren.</span>
            </div>
            <Footer />
        </div>
    );
}

export default Blank;
