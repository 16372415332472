import React from "react";
import "./ThankYou.css";

function ThankYou() {
    return (
        <div className="ThankYou">
            <div className="ThankYou__nextSteps">
                <div className="nextSteps__step">
                    {/* <span className="material-icons step__icon">mark_email_read</span> */}
                    <span className="step__headline">
                        <span className="material-icons">mark_email_read</span>&nbsp;Bestätigung per E-Mail
                    </span>
                    <span className="step__text">Du bekommst von uns eine Bestätigung per E-Mail mit allen wichtigen Infos.</span>
                </div>
                {window.innerWidth > 768 ? (
                    <span className="material-icons step__icon step__icon--arrow">arrow_forward</span>
                ) : (
                    <span className="material-icons step__icon step__icon--arrow">arrow_downward</span>
                )}
                <div className="nextSteps__step">
                    {/* <span className="material-icons step__icon">phone_callback</span> */}
                    <span className="step__headline">
                        <span className="material-icons">phone_callback</span>&nbsp;Kennenlerngespräch
                    </span>
                    <span className="step__text">Wir melden uns für ein lockeres 10 minütiges Kennenlerngespräch per Telefon.</span>
                </div>
            </div>
        </div>
    );
}

export default ThankYou;
