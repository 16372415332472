import React from "react";
import "./Mcq.css";

function Mcq({ id, iconAddon, icon, question, onClick }) {
    return (
        <div id={id} className="Mcq" onClick={onClick}>
            <div className="Mcq__iconContainer">
                {iconAddon !== "" ? (
                    <span className="material-icons iconContainer__icon--addon">
                        {iconAddon}
                    </span>
                ) : (
                    ""
                )}
                <span className="material-icons iconContainer__icon">
                    {icon}
                </span>
            </div>
            <span className="Mcq__question">{question}</span>
        </div>
    );
}

export default Mcq;
