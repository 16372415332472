import React from "react";
import "./Footer.css";

function Footer() {
    return (
        <div className="Footer">
            {/* <span className="Footer__jobTitle">Stellenbezeichnung: Zahnarzt</span> */}
            <span className="Footer__backBtn" onClick={() => window.location.reload(false)} rel="noreferrer">
                <span className="material-icons">restart_alt</span>Neustart
            </span>
            <div className="Footer__legal">
                <a href="http://b-smile.de/datenschutz" target="_blank" rel="noopener noreferrer">
                    Datenschutz
                </a>
                <span>&nbsp;&#124;&nbsp;</span>
                <a href="http://b-smile.de/impressum" target="_blank" rel="noopener noreferrer">
                    Impressum
                </a>
            </div>
            <div className="Footer__background"></div>
        </div>
    );
}

export default Footer;
