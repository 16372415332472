import React from "react";
import "./Saq.css";

function Saq({ id, placeholder, onInput, required }) {
    return (
        <div id={id} className="Saq">
            <textarea className="Saq__textarea" name={id} cols="80" rows="8" placeholder={placeholder} onInput={onInput} required={required}></textarea>
        </div>
    );
}

export default Saq;
