import React, { Fragment, useEffect, useState } from "react";
import "./Controls.css";

function Controls({ controlBefore, controlNext }) {
    // Hinweis: zurück Button vorübergehend Deaktiviert

    // useEffect(() => {
    //     setControlBeforeState(controlBefore);
    //     // console.log("controlBeforeState " + controlBeforeState);
    //     controlBeforeState
    //         ? document
    //               .querySelector(".Controls__before")
    //               .removeAttribute("disabled")
    //         : document
    //               .querySelector(".Controls__before")
    //               .setAttribute("disabled", "");
    // }, [controlBefore]);

    useEffect(() => {
        controlNext
            ? document
                  .querySelector(".Controls__next")
                  .removeAttribute("disabled")
            : document
                  .querySelector(".Controls__next")
                  .setAttribute("disabled", "");
    }, [controlNext]);

    // const ControlBefore = (e) => {
    //     e.preventDefault();
    //     const wrapper = document.querySelector(".wrapper");
    //     const wrapperWidth = wrapper.offsetWidth;
    //     wrapper.scrollLeft = wrapper.scrollLeft - wrapperWidth;
    // };

    const ControlNext = (e) => {
        e.preventDefault();
        const wrapper = document.querySelector(".wrapper");
        const wrapperWidth = wrapper.offsetWidth;

        if (controlNext) {
            wrapper.scrollLeft = wrapper.scrollLeft + wrapperWidth;
        }
    };

    return (
        <Fragment>
            {/* <button className="Controls__before" onClick={ControlBefore}>
                <span className="material-icons">arrow_back_ios</span>
                <span>Zurück</span>
            </button> */}
            <button
                type="button"
                className="Controls__next"
                onClick={ControlNext}
            >
                <span>Weiter</span>
                <span className="material-icons">arrow_forward_ios</span>
            </button>
        </Fragment>
    );
}

export default Controls;
