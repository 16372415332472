import React from "react";
import "./Header.css";

function Header({ progress }) {
    return (
        <div className="Header">
            <div className="Header__progressBar" style={{ width: progress + "%" }}></div>
            <img className="Header__logo" src="/B-SMILE-icon-r.svg" alt="B-Smile Logo" />
            <span className="Header__btn" id="Header__backBtn" onClick={() => window.location.reload(false)} rel="noreferrer">
                Neustart
            </span>
            <a className="Header__btn" href="https://b-smile.de/jobs" target="_blank" rel="noreferrer">
                Job-Übersicht
            </a>
        </div>
    );
}

export default Header;
