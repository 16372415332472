import React from "react";
import Cbq from "./componets/Cbq";
import Footer from "./componets/Footer";
import Header from "./componets/Header";
import Mcq from "./componets/Mcq";
import Saq from "./componets/Saq";
import Controls from "./componets/Controls";
import Final from "./componets/Final";
import { useEffect, useLayoutEffect, useState } from "react";
import ThankYou from "./componets/ThankYou";
import emailjs from "@emailjs/browser";
import Doc from "./componets/Doc";
import { useParams } from "react-router-dom";

function Instance({ questions, stelle }) {
    const [controlBeforeState, setControlBeforeState] = useState(false);
    const [controlNextState, setControlNextState] = useState(false);

    const [checkedElements, setCheckedElements] = useState([]);

    function CbqChange(event) {
        if (event.target.checked) {
            setCheckedElements([...checkedElements, event.target.id]);
        } else {
            if (checkedElements.indexOf(event.target.id) > -1) {
                setCheckedElements(checkedElements.filter((checkedElements) => checkedElements !== event.target.id));
            }
        }
    }

    function McqClick(event) {
        setCheckedElements([...checkedElements, event.target.id]);
        const wrapper = document.querySelector(".wrapper");
        const wrapperWidth = wrapper.offsetWidth;
        wrapper.scrollLeft = wrapper.scrollLeft + wrapperWidth;
    }

    function SaqInput(event) {
        if (event.target.required) {
            setControlNextState(false);
            if (event.target.value.length > 3) {
                setControlNextState(true);
                document.querySelector(".Controls__next").addEventListener("click", () => {
                    setCheckedElements([...checkedElements, event.target.name]);
                });
            } else {
                document.querySelector(".Controls__next").addEventListener("click", () => {
                    if (checkedElements.indexOf(event.target.name) > -1) {
                        setCheckedElements(checkedElements.filter((checkedElements) => checkedElements !== event.target.name));
                    }
                });
            }
        } else {
            setControlNextState(true);
            document.querySelector(".Controls__next").addEventListener("click", () => {
                if (event.target.value.length > 3) {
                    setCheckedElements([...checkedElements, event.target.name]);
                } else {
                    if (checkedElements.indexOf(event.target.name) > -1) {
                        setCheckedElements(checkedElements.filter((checkedElements) => checkedElements !== event.target.name));
                    }
                }
            });
        }
    }

    // "Next" Button Status für ersten Slide (TODO: bessere Lösung finden)
    useEffect(() => {
        checkedElements.length > 0 ? setControlNextState(true) : setControlNextState(false);
    }, [checkedElements]);

    const [progress, setProgress] = useState(0);

    useLayoutEffect(() => {
        const wrapper = document.querySelector(".wrapper");

        setProgress((100 / wrapper.scrollWidth) * (wrapper.scrollLeft + wrapper.offsetWidth));

        var isInViewport = function (elem) {
            var distance = elem.getBoundingClientRect();
            return (
                distance.top >= 0 &&
                distance.left >= 0 &&
                distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                distance.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

        var allMcq = document.querySelectorAll(".Mcq");
        var allSaq = document.querySelectorAll(".Saq");
        var allFinal = document.querySelectorAll(".Final");
        var allThankYou = document.querySelectorAll(".ThankYou");

        wrapper.addEventListener(
            "scroll",
            (event) => {
                setProgress((100 / wrapper.scrollWidth) * (wrapper.scrollLeft + wrapper.offsetWidth));

                allMcq.forEach((element) => {
                    if (isInViewport(element)) {
                        setControlNextState(false);
                    }
                });

                allSaq.forEach((element) => {
                    if (isInViewport(element)) {
                        setControlNextState(true);
                    }
                });

                allFinal.forEach((element) => {
                    if (isInViewport(element)) {
                        setControlNextState(false);
                    }
                });

                allThankYou.forEach((element) => {
                    if (isInViewport(element)) {
                        setControlNextState(false);
                    }
                });
            },
            false
        );
    }, []);

    const [uploadedImage, setUploadedImage] = useState(null);

    useEffect(() => {
        if (document.getElementById("uploadImage") === null || document.getElementById("uploadImage") === undefined) {
            return;
        }

        document.getElementById("uploadImage").addEventListener("change", function () {
            var file = document.getElementById("uploadImage").files[0];

            getBase64(file);

            function getBase64(file) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    setUploadedImage(reader.result);
                    if (file.size > 500000) {
                        reduce_image_file_size(reader.result);
                    }
                };
            }

            async function reduce_image_file_size(base64Str, MAX_WIDTH = 450, MAX_HEIGHT = 450) {
                let resized_base64 = await new Promise((resolve) => {
                    let img = new Image();
                    img.src = base64Str;
                    img.onload = () => {
                        let canvas = document.createElement("canvas");
                        let width = img.width;
                        let height = img.height;

                        if (width > height) {
                            if (width > MAX_WIDTH) {
                                height *= MAX_WIDTH / width;
                                width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        let ctx = canvas.getContext("2d");
                        ctx.drawImage(img, 0, 0, width, height);
                        resolve(canvas.toDataURL()); // this will return base64 image results after resize
                        setUploadedImage(canvas.toDataURL());
                    };
                });
                return resized_base64;
            }
        });
    }, []);

    const [btnResponseState, setBtnResponseState] = useState("waiting");

    const finalButton = (event) => {
        event.preventDefault();

        setBtnResponseState("loading");

        const wrapper = document.querySelector(".wrapper");
        const wrapperWidth = wrapper.offsetWidth;

        var idTypeCheckedElements = checkedElements.map((item) => item.split("_"));

        let splitCheckedElementsIds = idTypeCheckedElements.map((item) => item[0].split(""));

        let returnValue = splitCheckedElementsIds.map((item) => [
            questions[item[1]].question,
            questions[item[1]].type === "Saq"
                ? document.querySelector('[name="' + item[0] + item[1] + item[2] + item[3] + "_" + questions[item[1]].type + '"]').value
                : questions[item[1]].anwsers[item[3]].text,
        ]);

        var mailBody = returnValue.map(
            (item) =>
                "<ul style=" +
                "list-style: none; padding-inline-start: 0;" +
                "><li style=" +
                "font-weight: bold;" +
                ">" +
                item[0] +
                "</li><li>" +
                item[1] +
                "</li></ul>"
        );

        var templateParams = {
            name: document.getElementById("inputName").value,
            mail: document.getElementById("inputMailAdress").value,
            tel: document.getElementById("inputTel").value,
            message: mailBody.join(""),
            image_bewerber: uploadedImage,
            stelle_bewerber: stelle,
            korrekturURL:
                stelle.replace(/[^a-zA-Z ]/g, "") +
                "/korrektur%26" +
                encodeURIComponent(document.getElementById("inputName").value) +
                "%26" +
                encodeURIComponent(document.getElementById("inputMailAdress").value) +
                "%26" +
                encodeURIComponent(document.getElementById("inputTel").value),
            korrekturStatus: param ? "(korrektur Bewerbung)" : "",
        };

        console.log(returnValue);

        emailjs.send("service_r7yi8gp", "template_9dg4nku", templateParams).then(
            function (response) {
                console.log("SUCCESS!", response.status, response.text);
                if (response.status === 200) {
                    setBtnResponseState("loading");
                    wrapper.scrollLeft = wrapper.scrollLeft + wrapperWidth;
                }
            },
            function (error) {
                console.log("FAILED...", error);
                setBtnResponseState("error");
            }
        );
    };

    useEffect(() => {
        emailjs.init("7ea2JaPZ7BgNxgyHY");
    }, []);

    //Korrektur Request Handler

    var { param } = useParams();

    if (param) {
        var paramSplit = param.split("&");
        console.log(paramSplit);
    }

    return (
        <div className="wrapper">
            <Header progress={progress} />
            <Controls controlBefore={controlBeforeState} controlNext={controlNextState} />

            {questions.map((item) => {
                if (item.type === "Cbq") {
                    return (
                        <div key={item.key} className="wrapper--question">
                            <span className="h1">{item.question}</span>
                            <span className="h2">(Mehrfachauswahl möglich)</span>
                            <hr />
                            <div className="container--Cbq">
                                {item.anwsers.map((anwser) => (
                                    <Cbq
                                        id={"q" + item.key + "a" + anwser.key + "_" + item.type}
                                        key={"q" + item.key + "a" + anwser.key + "_" + item.type}
                                        question={anwser.text}
                                        onChange={(e) => CbqChange(e)}
                                    />
                                ))}
                            </div>
                            <span className="material-icons wrapper--question__scrollArrow">keyboard_arrow_down</span>
                        </div>
                    );
                } else if (item.type === "Mcq") {
                    return (
                        <div key={item.key} className="wrapper--question">
                            <span className="h1">{item.question}</span>
                            <hr />
                            <div className="container--Mcq">
                                {item.anwsers.map((anwser) => (
                                    <Mcq
                                        id={"q" + item.key + "a" + anwser.key + "_" + item.type}
                                        key={"q" + item.key + "a" + anwser.key + "_" + item.type}
                                        iconAddon={anwser.iconAddon}
                                        icon={anwser.icon}
                                        question={anwser.text}
                                        onClick={(e) => McqClick(e)}
                                    />
                                ))}
                            </div>
                        </div>
                    );
                } else if (item.type === "Saq") {
                    return (
                        <div key={item.key} className="wrapper--question">
                            <span className="h1">{item.question}</span>
                            <span className="h2">{item.optional ? "(optional)" : "(Plichtfeld)"}</span>
                            <hr />
                            <div className="container--Saq">
                                <Saq
                                    id={"q" + item.key + "a" + "0" + "_" + item.type}
                                    placeholder="z. B. Meine Erfahrung aus..."
                                    onInput={(e) => SaqInput(e)}
                                    required={!item.optional}
                                />
                            </div>
                        </div>
                    );
                } else if (item.type === "Doc") {
                    return (
                        <div key={item.key} className="wrapper--question">
                            <span className="h1">{item.question}</span>
                            <span className="h2">{item.optional ? "(optional)" : "(Plichtfeld)"}</span>
                            <hr />
                            <div className="container--Doc">
                                <Doc id={"q" + item.key + "a" + "0" + "_" + item.type} />
                            </div>
                        </div>
                    );
                } else {
                    return (
                        <div className="wrapper--question">
                            <p>Fehler: Kein Fragen Typ oder falscher Fragen Typ.</p>
                        </div>
                    );
                }
            })}

            <div className="wrapper--question">
                <span className="h1">Geschafft! Nun benötigen wir nur noch Ihre Kontaktdaten.</span>
                <hr />
                <div className="container--Final">
                    {param ? (
                        <Final onClick={(e) => finalButton(e)} responseState={btnResponseState} params={paramSplit} />
                    ) : (
                        <Final onClick={(e) => finalButton(e)} responseState={btnResponseState} />
                    )}
                </div>
            </div>

            <div className="wrapper--question">
                <span className="h1">Vielen Dank! Wie geht es nun weiter?</span>
                <hr />
                <div className="container--ThankYou">
                    <ThankYou />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Instance;
