import React, { useEffect } from "react";
import { useState } from "react";
import "./Doc.css";

function Doc() {
    // const [image, setImage] = useState();
    // function getImage() {
    //     console.log(document.getElementById("uploadImage").files[0]);
    //     console.log(URL.createObjectURL(document.getElementById("uploadImage").files[0]));
    //     setImage(URL.createObjectURL(document.getElementById("uploadImage").files[0]));
    // }

    return (
        <div className="Doc">
            {/* <div className="Doc__column">
                <img src={image} alt="Image" />
            </div> */}
            <div className="Doc__column">
                <div>
                    <label htmlFor="uploadImage" className="d-block">
                        Ein Portrait-Foto hochladen
                    </label>
                    {/* <input type="file" name="uploadImage" id="uploadImage" accept="image/*" onChange={getImage} /> */}
                    <input type="file" name="uploadImage" id="uploadImage" accept="image/png, image/jpeg, image/jpg" />
                </div>
                {/* <div>
                    <label htmlFor="uploadDocs" className="d-block">
                        Dokumente als PDF hochladen (z. B. Lebenslauf, Zeugnisse)
                    </label>
                    <input type="file" name="uploadDocs" id="uploadDocs" accept=".pdf" multiple />
                </div> */}
            </div>
        </div>
    );
}

export default Doc;
